import React from "react"

import "bootstrap/dist/css/bootstrap.min.css"

import MyNavbar from "./MyNavbar"
import Footer from "./Footer"

import "../assets/css/main.css"

const Layout = ({ children }) => {
  return (
    <>
      <MyNavbar />
      {children}
      <Footer />
    </>
  )
}

export default Layout
