import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import { List } from "react-bootstrap-icons"

const MyNavbar = () => {
  return (
    <header className="header">
      <Container className="header__container">
        <Navbar expand="lg" className="navbar">
          <Navbar.Brand>
            <Link className="navbar-brand" to="/">
              <StaticImage
                src="../assets/images/logo-main.png"
                height={70}
                alt="Adriana Beach Logo"
                placeholder="tracedSVG"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="basic-navbar-nav"
          >
            <List />
          </Navbar.Toggle>
          <Navbar.Collapse
            className="navbar-collapse collapse justify-content-between"
            id="navbar"
            collapse
          >
            <div></div>
            <Nav className="justify-content-center">
              <Nav.Link className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link className="nav-link" to="/gallery">
                  Gallery
                </Link>
              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link className="nav-link" to="/our-story">
                  Our Story
                </Link>
              </Nav.Link>
              <Nav.Link>
                <NavDropdown title="Stay">
                  <NavDropdown.Item>
                    <Link className="dropdown-item" to="/stay/deluxe-suites">
                      Deluxe Suites
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link
                      className="dropdown-item"
                      to="/stay/deluxe-apartments"
                    >
                      Deluxe Apartments
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link className="dropdown-item" to="/stay/junior-suites">
                      Junior Suites
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link
                      className="dropdown-item"
                      to="/stay/family-apartments"
                    >
                      Family Apartments
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </Nav.Link>
            </Nav>
            <Link className="nav-link btn btn-primary" to="/contact">
              Book Now
            </Link>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default MyNavbar
