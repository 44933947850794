import React from "react"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"

import { Facebook, Instagram } from "react-bootstrap-icons"

const Footer = () => {
  return (
    <footer className="footer pt-3 pb-1 mt-auto">
      <Container className="footer__navigation">
        <Navbar className="navbar">
          <Nav className="justify-content-center">
            <Nav.Item className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Link className="nav-link" to="/gallery">
                Gallery
              </Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Link className="nav-link" to="/our-story">
                Our Story
              </Link>
            </Nav.Item>
            <NavDropdown className="nav-item dropdown dropup" title="Stay">
              <NavDropdown.Item>
                <Link className="dropdown-item" to="/stay/deluxe-suites">
                  Deluxe Suites
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link className="dropdown-item" to="/stay/deluxe-apartments">
                  Deluxe Apartments
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link className="dropdown-item" to="/stay/junior-suites">
                  Junior Suites
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link className="dropdown-item" to="/stay/family-apartments">
                  Family Apartments
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </Nav.Item>
          </Nav>
          <Link className="btn btn-primary nav-link" to="/contact">
            Book Now
          </Link>
        </Navbar>
      </Container>
      <div className="container footer__social">
        <nav className="nav justify-content-center">
          <a
            href="https://www.facebook.com/adrianastudios"
            className="nav-link"
          >
            <Facebook />
          </a>
          <a href="https://instagram.com/adrianabeach" className="nav-link">
            <Instagram />
          </a>
        </nav>
      </div>
      <div className="container footer__copyright">
        <p className="footer__copyright__text text-center fs-6 fw-light mb-0">
          &copy; Copyright {new Date().getFullYear()}. Adriana Beach. All rights
          reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
