import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} | Adriana Beach`}
      meta={[
        {
          name: `description`,
          content:
            "Adriana Beach in Halkidiki, Greece provide luxury accommodation on the beachfront. A great holiday destination with views over the Aegean Sea.",
        },
        {
          name: `keywords`,
          content:
            "Adriana studios, Rooms to rent, Accomodation Halkidiki, Halkidiki, HALKIDIKI accommodation, Greek, greece, greek rooms, Greece rooms, lodging, apartments, studios, bungalows greece, rooms for rent greece, accommodation greece",
        },
        {
          name: `robots`,
          content: "all",
        },
      ]}
    ></Helmet>
  )
}

export default Seo
